import classnames from "classnames/bind";
import styles from "./FilePage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faFile,
  faFileVideo,
  faImage,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ItemFile from "../../components/ItemFile.js/ItemFile";
import { useEffect, useState } from "react";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import { getFiles } from "../../services/FileServices";
const cx = classnames.bind(styles);
function FilePage() {
  const [listFile, setListFile] = useState([]);
  const user = getUserInfoFromToken();
  useEffect(() => {
    const fetchFile = async () => {
      const rs = await getFiles(user.IDAccount);
      if (rs) {
        setListFile(rs.data);
      }
    };
    fetchFile();
  }, []);
  return (
    <div className={cx("wrapper")}>
      <div className={cx("title")}>
        <h2>File của bạn</h2>
      </div>
      <div className={cx("search")}>
        <FontAwesomeIcon icon={faSearch} className={cx("icon_search")} />
        <input placeholder="Nhập tên file" type="text"></input>
      </div>
      <div className={cx("list")}>
        {listFile.map((file) => {
          return <ItemFile file={file} />;
        })}
      </div>
    </div>
  );
}

export default FilePage;
