import classnames from "classnames/bind";
import styles from "./ItemFile.module.scss";
import {
  faEllipsisV,
  faFile,
  faFileVideo,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { formatNewDate } from "../../utils/dateUtils";
const cx = classnames.bind(styles);
function ItemFile({ file }) {
  const [state, setState] = useState();
  return (
    <div className={cx("item_file")}>
      <a className={cx("name")} href={file.Path} target="_blank">
        {file.FileType == "image" ? <FontAwesomeIcon icon={faImage} /> : <></>}
        {file.FileType == "video" ? (
          <FontAwesomeIcon icon={faFileVideo} />
        ) : (
          <></>
        )}
        {file.FileType == "file" ? <FontAwesomeIcon icon={faFile} /> : <></>}

        <span className={cx("name_file")}>{file.filename}</span>
      </a>
      <span className={cx("create_at")}>{formatNewDate(file.Create_at)}</span>
      <div
        className={cx("icon_actions")}
        onClick={() => {
          setState(!state);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      {state == true ? (
        <Dropdown width={"200px"} right={"0"} top={"100%"}>
          <ul className={cx("list_option")}>
            <li>
              <a href={`/post?id=${file.IDPost}`}>Xem post</a>
            </li>
            <li>
              <a href={file.Path} download={file.filename}>
                Tải xuống
              </a>
            </li>
          </ul>
        </Dropdown>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ItemFile;
