import axios from "axios";
const token = localStorage.getItem("token");

export const getPosts = async () => {
  try {
    const response = await axios.get("https://pycheck.xyz/api/v1/posts", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const getPostWithId = async (IDPost) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/getPostWithId",
      { IDPost },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const getPostsIdPersonal = async (ID) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/getPostsIdPersonal",
      {
        ID,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const addPost = async (IDAccount, Content, Size = 0) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/addPost",
      {
        IDAccount,
        Content,
        Size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const deletePost = async (IDPost) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/deletePost",
      {
        IDPost,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const updatePost = async (IDPost, Content) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/updatePost",
      {
        IDPost,
        Content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const getCountLike = async (IDPost, IDAccount) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/getCountLike",
      { IDPost, IDAccount },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const getCountComment = async (IDPost) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/getCountComment",
      { IDPost },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const checkUserLike = async (IDAccount, IDPost) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/checkUserLike",
      { IDAccount, IDPost },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const savePost = async (IDAccount, IDPost) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/savePost",
      { IDAccount, IDPost },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const checkSavePost = async (IDAccount, IDPost) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/checkSavePost",
      { IDAccount, IDPost },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const getSavePost = async (IDAccount) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/getSavePost",
      { IDAccount },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const deleteSavePost = async (ID) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/deleteSavePost",
      { ID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const getReportType = async () => {
  try {
    const response = await axios.get(
      "https://pycheck.xyz/api/v1/getReportType",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
export const reportPost = async (IDType, IDPost, IDSender) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/reportPost",
      { IDType, IDPost, IDSender },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
