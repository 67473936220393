import classnames from "classnames/bind";
import styles from "./FriendPage.module.scss";
import ContainerPersonal from "../../components/layouts/ContainerPersonal/ContainerPersonal";
import { useContext, useEffect, useState } from "react";
import FriendItem from "../../components/FriendItem/FriendItem";
import { useMediaQuery } from "react-responsive";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import { Context } from "../../contexts/Context";
import {
  getInforUser,
  getMyFriends,
  getMyFriendSend,
  getMySended,
} from "../../services/UserServices";
const cx = classnames.bind(styles);
function FriendPage() {
  const [indexNav, setIndexNav] = useState(0);
  const [user, setUser] = useState();
  const [list, setList] = useState([]);
  const { setLoadding } = useContext(Context);

  const userMain = getUserInfoFromToken();

  useEffect(() => {
    const fetchUser = async () => {
      await setLoadding(true);
      const responseImage = await getInforUser(userMain.MSV);
      setUser(responseImage.data[0]);
      await setLoadding(false);
    };
    fetchUser();
  }, []);
  useEffect(() => {
    const fetchFriends = async () => {
      switch (indexNav) {
        case 1:
          const rs1 = await getMyFriendSend(user.ID);
          setList(rs1.data);
          break;
        case 2:
          const rs2 = await getMySended(user.ID);
          setList(rs2.data);
          break;
        default:
          const rs = await getMyFriends(user.ID);
          setList(rs.data);
          break;
      }
    };
    if (user != undefined) {
      fetchFriends();
    }
  }, [indexNav, user]);
  return (
    <ContainerPersonal user={user}>
      <div className={cx("wrapper")}>
        <div className={cx("container")}>
          <div className={cx("titles")}>
            <div
              className={cx("item_title", indexNav == 0 ? "active" : "")}
              onClick={() => {
                setIndexNav(0);
              }}
            >
              <span>Danh sách bạn bè</span>
            </div>
            <div
              className={cx("item_title", indexNav == 1 ? "active" : "")}
              onClick={() => {
                setIndexNav(1);
              }}
            >
              <span>Lời mời kết bạn</span>
            </div>
            <div
              className={cx("item_title", indexNav == 2 ? "active" : "")}
              onClick={() => {
                setIndexNav(2);
              }}
            >
              <span>Lời mời đã gửi</span>
            </div>
          </div>
          <div className={cx("content")}>
            <div className={cx("list")}>
              {list.map((item, index) => {
                if (indexNav == 1) {
                  return (
                    <FriendItem
                      messeage={item}
                      invite={true}
                      handleAcc={(state) => {
                        setIndexNav(state);
                      }}
                    />
                  );
                }
                if (indexNav == 2) {
                  return (
                    <FriendItem
                      messeage={item}
                      sended={true}
                      handleAcc={(state) => {
                        setIndexNav(state);
                      }}
                    />
                  );
                }
                if (indexNav == 0) {
                  return (
                    <FriendItem
                      messeage={item}
                      friended={true}
                      handleAcc={(state) => {
                        setIndexNav(state);
                      }}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </ContainerPersonal>
  );
}

export default FriendPage;
