import { useEffect, useState } from "react";
import { Context } from "./Context";
import { useMediaQuery } from "react-responsive";

function Provider({ children }) {
  const [menufix, setmenufix] = useState(false);
  const [loadding, setLoadding] = useState(false);
  const [dataNoti, setDataNoti] = useState(null);
  const [stateCall, setStateCall] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [docs, setDocs] = useState(null);
  const token = localStorage.getItem("token");
  const isMobile = useMediaQuery({ maxWidth: 670 });
  useEffect(() => {
    if (dataNoti != null) {
      const timer = setTimeout(() => {
        setDataNoti(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [dataNoti]);
  useEffect(() => {
    const valueScroll = isMobile == true ? 126 : 20;
    const fixedmenu = () => {
      if (
        document.body.scrollTop > valueScroll ||
        document.documentElement.scrollTop > valueScroll
      ) {
        setmenufix(true);
      } else {
        setmenufix(false);
      }
    };
    window.addEventListener("scroll", fixedmenu);
    return () => {
      window.removeEventListener("scroll", fixedmenu);
    };
  });
  return (
    <Context.Provider
      value={{
        menufix,
        setmenufix,
        token,
        loadding,
        setLoadding,
        dataNoti,
        setDataNoti,
        selectedImage,
        setSelectedImage,
        stateCall,
        setStateCall,
        selectedVideo,
        setSelectedVideo,
        docs,
        setDocs,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Provider;
