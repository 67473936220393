import classnames from "classnames/bind";
import styles from "./FriendItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

import Dropdown from "../Dropdown/Dropdown";
import { useState } from "react";
import images from "../../assets/images";
import {
  acceptInvite,
  cancelInvite,
  declineInvite,
  deleteInvite,
} from "../../services/UserServices";
import io from "socket.io-client";
const cx = classnames.bind(styles);
function FriendItem({
  onClick = null,
  messeage = null,
  width,
  invite = null,
  friended = null,
  sended = null,
  active,
  handleAcc,
}) {
  const [socket, setSocket] = useState(
    io("https://pycheck.xyz", {
      transports: ["websocket"],
      upgrade: true,
    })
  );
  const [stateActionMess, setStateActionMess] = useState(false);
  const handleAccept = async () => {
    await acceptInvite(messeage.idFriendShips);
    const IDPost = messeage.idFriendShips;
    const IDAccountPost = messeage.IDCustomer1;
    const Sender_id = messeage.IDCustomer2;
    const stateNoti = "inviteaccept";
    socket.emit("postNotification", {
      IDPost,
      IDAccountPost,
      Sender_id,
      stateNoti,
    });
    handleAcc(0);
  };
  const handleCancel = async () => {
    if (invite == true) {
      await deleteInvite(messeage.idFriendShips);
      handleAcc(0);
    }
    if (sended == true) {
      const rs = await cancelInvite(messeage.IDCustomer1, messeage.IDCustomer2);
      if (rs.status == 200) {
        handleAcc(0);
      }
    }
    if (friended == true) {
      await deleteInvite(messeage.idFriendShips);
      window.location.reload();
      handleAcc(0);
    }
  };
  return (
    <div
      className={cx(
        "wrapper",
        messeage != null ? "mess" : "",
        sended != null || friended != null || invite != null ? "friend" : "",
        active == true ? "active" : ""
      )}
      style={{ width: width }}
      onClick={() => {
        if (invite == null && friended == null && sended == null) {
          onClick(messeage);
        }
      }}
    >
      <div className={cx("image_user", messeage != null ? "image_mess" : "")}>
        <img
          style={{ objectFit: "cover" }}
          src={
            messeage.image_user == null
              ? images.default_image
              : messeage.image_user
          }
        ></img>
      </div>
      <div className={cx("name_action")}>
        <div className={cx("name")}>
          <p>{messeage.Name}</p>
        </div>
        <div className={cx("action_button")}>
          {invite == true ? (
            <button className={cx("active")} onClick={handleAccept}>
              <p>Xác nhận</p>
            </button>
          ) : (
            ""
          )}
          {/* {messeage != null ? (
            <span className={cx("last_mess")}>Bạn:oke </span>
          ) : (
            <></>
          )} */}
          <button className={cx("non_active")} onClick={handleCancel}>
            {invite == true ? <p>Xóa</p> : ""}
            {friended == true ? <p>Hủy kết bạn</p> : ""}
            {sended == true ? <p>Hủy lời mời</p> : ""}
          </button>
        </div>
      </div>
      {messeage != null ? (
        <div className={cx("action_mess_container")}>
          <div
            className={cx("icon_action")}
            onClick={() => {
              setStateActionMess(!stateActionMess);
            }}
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
          {stateActionMess == true ? (
            <Dropdown top={"100%"} right={0}>
              <ul>
                <li>
                  <span>Xóa</span>
                </li>
              </ul>
            </Dropdown>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default FriendItem;
