import classnames from "classnames/bind";
import styles from "./ContainerPersonal.module.scss";
import {
  faAdd,
  faCheck,
  faChevronDown,
  faChevronUp,
  faClose,
  faMessage,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import Dropdown from "../../Dropdown/Dropdown";
import images from "../../../assets/images";
import { getUserInfoFromToken } from "../../../utils/tokenUtils";
import {
  addConvensation,
  checkConvensation,
} from "../../../services/MessServices";
import { Context } from "../../../contexts/Context";
import io from "socket.io-client";
import {
  cancelInvite,
  checkFriend,
  getMyFriends,
} from "../../../services/UserServices";
const cx = classnames.bind(styles);
function ContainerPersonal({ children, user }) {
  const [stateDropdownOption, setStateDropdownOption] = useState(false);
  const [stateAddFriend, setStateAddFriend] = useState(null);
  const [countFriends, setcountFriends] = useState(0);
  const { setSelectedImage } = useContext(Context);
  const [socket, setSocket] = useState(
    io("https://pycheck.xyz", {
      transports: ["websocket"],
      upgrade: true,
    })
  );

  const userMain = getUserInfoFromToken();

  const handleGotoMess = async () => {
    const responseCheck = await checkConvensation(user.ID);
    if (responseCheck.data.length == 0) {
      const responseAddConven = await addConvensation(
        userMain.IDAccount,
        user.ID
      );
      if (responseAddConven.status == 200) {
        window.location.href = `/messenger?mess=${responseAddConven.data[0]}`;
      }
    } else {
      window.location.href = `/messenger?mess=${responseCheck.data[0].ID}`;
    }
  };
  const handleLoadImage = (url) => {
    setSelectedImage(url);
  };

  const handleAddFriend = async () => {
    const ID1 = userMain.IDAccount;
    const ID2 = user.ID;
    if (stateAddFriend == null) {
      await socket.emit("addFriend", { ID1, ID2 });
      setStateAddFriend(false);
    } else {
      if (stateAddFriend == false) {
        const rs = await cancelInvite(ID1, ID2);
        if (rs.status == 200) {
          setStateAddFriend(null);
        }
      }
    }
  };
  useEffect(() => {
    const fetchStateFriend = async () => {
      const responseCheckFriend = await checkFriend(
        userMain.IDAccount,
        user.ID
      );
      const friend = responseCheckFriend.data[0];
      if (friend != undefined) {
        if (friend.Status == "pending") {
          setStateAddFriend(false);
        } else {
          if (friend.Status == "declined") {
            setStateAddFriend(null);
          } else {
            setStateAddFriend(true);
          }
        }
      }
    };
    const fetchCountFriends = async () => {
      const rs = await getMyFriends(user.ID);
      setcountFriends(rs.data.length);
    };
    if (user != undefined) {
      fetchStateFriend();
      fetchCountFriends();
    }
  }, [user]);
  return (
    <div className={cx("wrapper")}>
      <div className={cx("container_wall_personal")}>
        <div className={cx("wall")}>
          <div className={cx("infor_user")}>
            <div
              className={cx("banner")}
              onClick={() => {
                handleLoadImage(user.image_banner);
              }}
            >
              {user != undefined ? (
                <img
                  src={
                    user.image_banner == null
                      ? images.default_banner
                      : user.image_banner
                  }
                ></img>
              ) : (
                <></>
              )}
            </div>
            <div className={cx("infor")}>
              <div className={cx("infor_left")}>
                <div
                  className={cx("image_user")}
                  onClick={() => {
                    handleLoadImage(user.image_user);
                  }}
                >
                  {user != undefined ? (
                    <img
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "cover",
                      }}
                      src={
                        user.image_user == null
                          ? images.default_image
                          : user.image_user
                      }
                    ></img>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={cx("name_friends")}>
                  <h2>{user != undefined ? user.Name : ""}</h2>
                  <span>{countFriends} bạn bè</span>
                </div>
              </div>
              <div className={cx("infor_right")}>
                {user &&
                ((user.ID && user.ID !== userMain.IDAccount) ||
                  (user.ID === undefined &&
                    user.IDAccount !== userMain.IDAccount)) ? (
                  <>
                    <div className={cx("messeger")} onClick={handleGotoMess}>
                      <FontAwesomeIcon icon={faMessage} />
                      <span>Gửi tin nhắn</span>
                    </div>
                    <div
                      className={cx(
                        stateAddFriend == false ? "addFriend" : "pendingFriend"
                      )}
                      onClick={handleAddFriend}
                    >
                      <FontAwesomeIcon
                        icon={
                          stateAddFriend == null
                            ? faAdd
                            : stateAddFriend == false
                            ? faClose
                            : faCheck
                        }
                      />
                      <span>
                        {stateAddFriend == null
                          ? "Thêm bạn bè"
                          : stateAddFriend == false
                          ? "Hủy lời mời"
                          : "Bạn bè"}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className={cx("update_infor")}>
                    <FontAwesomeIcon icon={faPen} />
                    <span>Chỉnh sửa trang cá nhân</span>
                  </div>
                )}

                <div className={cx("container_action_other")}>
                  <div
                    className={cx("icon_chervon", "update_infor")}
                    onClick={() => {
                      setStateDropdownOption(!stateDropdownOption);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        stateDropdownOption == false
                          ? faChevronDown
                          : faChevronUp
                      }
                    />
                  </div>
                  {stateDropdownOption == true ? (
                    <Dropdown width={"200px"} right={"0"}>
                      <ul className={cx("list_option")}>
                        <li>
                          <a href="/save-posts">Các mục đã lưu</a>
                        </li>
                      </ul>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default ContainerPersonal;
