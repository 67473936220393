import classnames from "classnames/bind";
import styles from "./LoadImage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useContext, useRef, useState } from "react";
import { Context } from "../../../contexts/Context";
const cx = classnames.bind(styles);

function LoadImage({ image, state = null }) {
  const { selectedImage, setSelectedImage, setSelectedVideo } =
    useContext(Context);
  const [stateVideo, setStateVideo] = useState(false);
  const videoRef = useRef();
  const handleVideo = () => {
    if (stateVideo == true) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };
  return (
    <div className={cx("wrapper_popup")}>
      <div className={cx("container_popup")}>
        {state == null ? (
          <img src={image}></img>
        ) : (
          <video
            onClick={() => {
              setStateVideo(!stateVideo);
              handleVideo();
            }}
            ref={videoRef}
            autoPlay
            src={image}
          ></video>
        )}
      </div>
      <div className={cx("black")}></div>
      <div
        className={cx("close")}
        onClick={() => {
          if (state == null) {
            setSelectedImage(null);
          } else {
            videoRef.current.pause();
            setSelectedVideo(null);
          }
        }}
      >
        <FontAwesomeIcon className={cx("icon")} icon={faClose} />
      </div>
    </div>
  );
}

export default LoadImage;
