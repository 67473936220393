import classnames from "classnames/bind";
import styles from "./VideoCall.module.scss";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import {
  faMicrophone,
  faVideo,
  faVideoSlash,
  faVolumeHigh,
  faMicrophoneSlash,
  faPhoneSlash,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";

import { getUserInfoFromToken } from "../../utils/tokenUtils";

const cx = classnames.bind(styles);

function VideoCall() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const userId = query.get("room_id");
  const user = getUserInfoFromToken();
  const isMobile = useMediaQuery({ maxWidth: 670 });

  const socket = io("https://pycheck.xyz", {
    transports: ["websocket"],
    upgrade: true,
  });

  const [videoState, setVideoState] = useState(true);
  const [micState, setMicState] = useState(true);

  const [volumnState, setVolumnState] = useState(true);
  const [me, setMe] = useState("");
  const [stream, setStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [name, setName] = useState("");

  const [volumeState, setVolumeState] = useState(true);

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: { echoCancellation: false } })
      .then((stream) => {
        setStream(stream);
        myVideo.current.srcObject = stream;
        callUser(userId, stream);
      });

    socket.on("me", (id) => {
      setMe(id);
    });
    // socket.on("callUser", (data) => {
    //   setReceivingCall(true);
    //   setCaller(data.from);
    //   setName(data.name);
    //   setCallerSignal(data.signal);
    // });
  }, []);
  const callUser = (id, str) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: str,
    });
    socket.emit("joinSocial", user.IDAccount);
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: parseInt(id),
        signalData: data,
        from: user.IDAccount,
        MSV: user.MSV,
        name: user.Name,
      });
    });
    peer.on("icecandidate", (event) => {
      if (event.candidate) {
        socket.emit("ice-candidate", {
          candidate: event.candidate,
          to: parseInt(userId),
        });
      }
    });
    peer.on("error", (err) => {
      console.error("Peer connection error:", err);
    });
    peer.on("stream", (s) => {
      userVideo.current.srcObject = s;
      userVideo.current.muted = false;
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });

    connectionRef.current = peer;
  };
  const leaveCall = () => {
    socket.emit("endCall", { to: parseInt(userId) });
  };
  socket.on("callEnded", async () => {
    setCallEnded(true);
    connectionRef.current.destroy();
    await socket.emit("endCall", { to: parseInt(userId) });
    window.location.href = "/messenger";
  });
  const toggleVideo = (state) => {
    setVideoState(state);
    stream.getVideoTracks()[0].enabled = !videoState;
  };
  const toggleAudio = (state) => {
    setVolumnState(state);
    stream.getAudioTracks()[0].enabled = !volumnState;
  };
  return (
    <div className={cx("container_video")}>
      <video
        className={cx(isMobile == false ? "video_main" : "video_hide")}
        ref={myVideo}
        autoPlay
        playsInline
        muted
      />
      {callAccepted && !callEnded ? (
        <video
          className={cx(isMobile == false ? "video_hide" : "video_main")}
          playsInline
          ref={userVideo}
          muted
          autoPlay
        />
      ) : null}

      <div className={cx("video_action")}>
        {volumnState ? (
          <FontAwesomeIcon
            className={cx("icon", "volume")}
            icon={faVolumeHigh}
            onClick={() => toggleAudio(false)}
          />
        ) : (
          <FontAwesomeIcon
            className={cx("icon", "video")}
            icon={faVolumeXmark}
            onClick={() => toggleAudio(true)}
          />
        )}

        <FontAwesomeIcon
          className={cx("icon")}
          icon={micState ? faMicrophone : faMicrophoneSlash}
          onClick={() => toggleAudio(!micState)}
        />

        {videoState ? (
          <FontAwesomeIcon
            className={cx("icon", "video")}
            icon={faVideo}
            onClick={() => toggleVideo(false)}
          />
        ) : (
          <FontAwesomeIcon
            className={cx("icon", "video")}
            icon={faVideoSlash}
            onClick={() => toggleVideo(true)}
          />
        )}
        <FontAwesomeIcon
          className={cx("icon", "mic")}
          icon={faPhoneSlash}
          onClick={() => {
            leaveCall();
          }}
        />
      </div>
    </div>
  );
}

export default VideoCall;
