import classnames from "classnames/bind";
import styles from "./PopupCallNoti.module.scss";
import Popup from "../Popup/Popup";
import { useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import { Context } from "../../contexts/Context";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhoneSlash,
  faVideo,
  faVideoSlash,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons/faArrowTrendUp";
const cx = classnames.bind(styles);

const socket = io("https://pycheck.xyz", {
  transports: ["websocket"],
  upgrade: true,
});
function PopupCallNoti() {
  const user = getUserInfoFromToken();
  const { stateCall, setStateCall } = useContext(Context);
  const [videoState, setVideoState] = useState(true);
  const [micState, setMicState] = useState(true);
  const [volumnState, setVolumnState] = useState(true);
  const [me, setMe] = useState("");
  const [stream, setStream] = useState(null);
  const [callEnded, setCallEnded] = useState(false);
  const [acceppt, setAccept] = useState(false);
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: { echoCancellation: true } })
      .then((stream) => {
        setStream(stream);
        myVideo.current.srcObject = stream;
        // stream.getAudioTracks()[0].enabled = false;
      });
    socket.on("me", (id) => {
      setMe(id);
    });
  }, []);
  const answerCall = () => {
    // stream.getAudioTracks()[0].enabled = true;
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    socket.on("ice-candidate", (data) => {
      peer.addIceCandidate(new RTCIceCandidate(data.candidate));
    });

    peer.on("signal", (data) => {
      socket.emit("answerCall", {
        signal: data,
        to: parseInt(stateCall.from),
      });
    });
    peer.on("stream", (stream) => {
      userVideo.current.srcObject = stream;
      userVideo.current.muted = false;
    });

    peer.signal(stateCall.signal);
    connectionRef.current = peer;
    setAccept(!acceppt);
  };
  const toggleVideo = (state) => {
    setVideoState(state);
    stream.getVideoTracks()[0].enabled = !videoState;
  };
  const toggleAudio = (state) => {
    setVolumnState(state);
    stream.getAudioTracks()[0].enabled = !volumnState;
  };
  const leaveCall = () => {
    // setCallEnded(true);
    // connectionRef.current.destroy();
    socket.emit("endCall", { to: parseInt(stateCall.from) });
  };
  socket.emit("joinSocial", user.IDAccount);
  socket.on("callEnded", async () => {
    setCallEnded(true);
    connectionRef.current.destroy();
    stream.getTracks().forEach((track) => track.stop());
    window.location.href = "/messenger";
  });
  return (
    <Popup height="80vh">
      <div className={cx("wrapper")}>
        {acceppt == false ? (
          <>
            <div className={cx("title")}>{stateCall.Name} gọi kìa</div>
            <div className={cx("thumb")}>
              <img src={stateCall.image_user}></img>
            </div>
            <div className={cx("question")}>
              <p>Bạn bắt máy chứ</p>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className={cx("test")}>
          <video
            className={cx("video_main")}
            playsInline
            ref={userVideo}
            autoPlay
            style={{ width: "300px" }}
          />
          <video
            className={cx("video_hide")}
            playsInline
            ref={myVideo}
            autoPlay
            style={{ width: "300px" }}
          />
        </div>
        <div className={cx("actions")}>
          {acceppt == true ? (
            <div className={cx("video_action")}>
              {volumnState ? (
                <FontAwesomeIcon
                  className={cx("icon", "volume")}
                  icon={faVolumeHigh}
                  onClick={() => toggleAudio(false)}
                />
              ) : (
                <FontAwesomeIcon
                  className={cx("icon", "video")}
                  icon={faVolumeXmark}
                  onClick={() => toggleAudio(true)}
                />
              )}

              <FontAwesomeIcon
                className={cx("icon")}
                icon={micState ? faMicrophone : faMicrophoneSlash}
                onClick={() => toggleAudio(!micState)}
              />

              {videoState ? (
                <FontAwesomeIcon
                  className={cx("icon", "video")}
                  icon={faVideo}
                  onClick={() => toggleVideo(false)}
                />
              ) : (
                <FontAwesomeIcon
                  className={cx("icon", "video")}
                  icon={faVideoSlash}
                  onClick={() => toggleVideo(true)}
                />
              )}
              <FontAwesomeIcon
                className={cx("icon", "mic")}
                icon={faPhoneSlash}
                onClick={() => {
                  leaveCall();
                }}
              />
            </div>
          ) : (
            <>
              <button className={cx("btn", "yes")} onClick={answerCall}>
                <span>Bắt máy</span>
              </button>
              <button
                className={cx("btn", "no")}
                onClick={() => {
                  setStateCall(null);
                }}
              >
                <span>Từ chối</span>
              </button>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
}

export default PopupCallNoti;
