import classnames from "classnames/bind";
import styles from "./PostSave.module.scss";
import ContainerPersonal from "../../components/layouts/ContainerPersonal/ContainerPersonal";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import { useContext, useEffect, useState } from "react";
import { getInforUser } from "../../services/UserServices";
import { Context } from "../../contexts/Context";
import ItemPostList from "../../components/ItemPostList/ItemPostList";
import { getSavePost } from "../../services/PostServices";
const cx = classnames.bind(styles);
function PostSave() {
  const [user, setUser] = useState();
  const [posts, setPosts] = useState([]);
  const { setLoadding } = useContext(Context);
  const userMain = getUserInfoFromToken();
  useEffect(() => {
    const msv = userMain.MSV;
    const fetchPost = async () => {
      const rsPost = await getSavePost(userMain.IDAccount);
      setPosts(rsPost.data);
    };
    const fetchUser = async () => {
      await setLoadding(true);
      const responseImage = await getInforUser(msv);
      setUser(responseImage.data[0]);
      await setLoadding(false);
    };
    fetchPost();
    fetchUser();
  }, []);
  const handleFilterData = async (data) => {
    const filter = await posts.filter((post) => post.ID != data.ID);
    setPosts(filter);
  };
  return (
    <ContainerPersonal user={user}>
      <div className={cx("container_postsave")}>
        <div className={cx("title_page")}>
          <h2>Các post đã lưu</h2>
        </div>
        <div className={cx("list_post")}>
          {posts.map((post) => {
            return (
              <ItemPostList deleteSuccess={handleFilterData} data={post} />
            );
          })}
        </div>
      </div>
    </ContainerPersonal>
  );
}

export default PostSave;
