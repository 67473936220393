import classnames from "classnames/bind";
import styles from "./DragFile.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFile } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { getFileType } from "../../utils/fileUtils";
import * as tf from "@tensorflow/tfjs";
import { UploadFile, UploadFolder } from "../../services/FileServices";
import models from "../../assets/models/index.js";
import axios from "axios";
const cx = classnames.bind(styles);
function DragFile({
  handleClose,
  handleSendFile,
  stateUpload,
  handleStateUpload,
  fileUpdate = null,
}) {
  const [previewImages, setPreviewImages] = useState(null);
  const [files, setFiles] = useState(null);
  const [file, setFile] = useState(null);
  const [update, setUpdate] = useState(fileUpdate);
  const [prediction, setPrediction] = useState();
  const [model, setModel] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const refInputFile = useRef();
  const handleInputFile = () => {
    refInputFile.current.click();
  };
  useEffect(() => {
    // const loadModel = async () => {
    //   try {
    //     const modelURL = "https://pycheck.xyz/download/model.json";
    //     const handler = tf.io.browserHTTPRequest(modelURL);
    //     const loadedModel = await tf.loadLayersModel(handler); // Đường dẫn tới model.json
    //     setModel(loadedModel);
    //     console.log("Model loaded successfully!");
    //   } catch (err) {
    //     console.error("Error loading model:", err);
    //   }
    // };

    if (stateUpload == true) {
      const formData = new FormData();
      formData.append("file", files);
      UploadFolder(formData);
      handleStateUpload(false);
      // const formData = new FormData();
      // formData.append("file", files);
      // try {
      //   const response = axios.post("http://localhost:5000/predict", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   });
      //   handleStateUpload(false);
      //   setPrediction(response);
      // } catch (error) {
      //   console.error("There was an error!", error);
      // }
    }
    // loadModel();
  }, [stateUpload]);

  const handleFileChange = async (event) => {
    setUpdate(null);
    const selectedFile = event.target.files[0];
    const filetype = getFileType(selectedFile.name);
    if (selectedFile != undefined) {
      // const image = await fileToImage(selectedFile);
      // setImageURL(URL.createObjectURL(selectedFile));

      // // Chuẩn bị ảnh và dự đoán
      // const tensor = processImage(image);
      // const predictions = model.predict(tensor);

      // // Lấy kết quả dự đoán
      // predictions.array().then((result) => {
      //   const predictedClass = result[0][0] > 0.5 ? "Porn" : "Non-Porn"; // Ngưỡng 0.5 để phân loại
      //   setPrediction(predictedClass);
      // });
      if (filetype == "image") {
        setPreviewImages([URL.createObjectURL(selectedFile)]);
      } else {
        setFile(selectedFile);
      }
      setFiles(selectedFile);
    }
    handleSendFile(selectedFile.name, filetype, selectedFile.size);
  };
  // const fileToImage = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const img = new Image();
  //       img.src = reader.result;
  //       img.onload = () => resolve(img);
  //       img.onerror = (err) => reject(err);
  //     };
  //     reader.onerror = (err) => reject(err);
  //     reader.readAsDataURL(file);
  //   });
  // };
  // const processImage = (img) => {
  //   const imageTensor = tf.browser
  //     .fromPixels(img)
  //     .resizeNearestNeighbor([224, 224]) // Resize về đúng kích thước mà mô hình yêu cầu
  //     .expandDims() // Thêm chiều batch
  //     .toFloat()
  //     .div(tf.scalar(255)); // Chuẩn hóa giá trị pixel về khoảng [0, 1]
  //   return imageTensor;
  // };

  return (
    <>
      {file != null ? (
        <div className={cx("file")}>
          <div>
            <div className={cx("icon_file")}>
              <FontAwesomeIcon icon={faFile} />
            </div>
            <span>{file.name}</span>
          </div>
          <FontAwesomeIcon
            icon={faClose}
            className={cx("icon")}
            onClick={() => {
              setFile(null);
              setFiles(null);
            }}
          />
        </div>
      ) : (
        <div className={cx("wrapper")}>
          <input
            onChange={handleFileChange}
            ref={refInputFile}
            type="file"
            hidden
          ></input>
          <div className={cx("container")}>
            <div
              className={cx("train")}
              style={
                previewImages == null && update == null
                  ? { backgroundColor: "#f2f3f4" }
                  : {
                      backgroundImage: `url(${
                        update != null ? update.Path : previewImages
                      })`,
                      backgroundSize: "cover",
                    }
              }
              onClick={handleInputFile}
            >
              {previewImages == null ? (
                <>
                  <h3>Thêm ảnh / video / file</h3>
                  <p>hoặc kéo thả</p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div
              className={cx("close")}
              onClick={() => {
                handleClose(false);
              }}
            >
              <FontAwesomeIcon icon={faClose} className={cx("icon_close")} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DragFile;
