import classnames from "classnames/bind";
import styles from "./CreatePost.module.scss";
import { useMediaQuery } from "react-responsive";

import Popup from "../Popup/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEarthAmerica,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import TextAreaInput from "../TextAreaInput/TextAreaInput";
import DragFile from "../DragFile/DragFile";
import { useEffect, useState } from "react";
import { addPost, updatePost } from "../../services/PostServices";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import { formatNewDate } from "../../utils/dateUtils";
import { updateFilePost, UploadFile } from "../../services/FileServices";
import { getInforUser } from "../../services/UserServices";
import images from "../../assets/images";
import { handleSizeFile } from "../../utils/fileUtils";
const cx = classnames.bind(styles);
function CreatePost({ handleClose, dataUpdate = null }) {
  const [stateUpload, setStateUpload] = useState(false);
  const [stateHandleUpload, setStateHandleUpload] = useState(false);
  const [textPost, setTextPost] = useState("");
  const [file, setFile] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 670 });
  const [inforUser, setInforUser] = useState(null);

  const user = getUserInfoFromToken();
  const handleStateUpload = (s) => {
    if (s == null) {
      setStateUpload(true);
    } else {
      setStateUpload(false);
      setFile(null);
    }
  };
  useEffect(() => {
    const fetchInforUser = async () => {
      const responseUser = await getInforUser(user.MSV);
      setInforUser(responseUser.data[0]);
    };
    if (dataUpdate != null) {
      setTextPost(dataUpdate.Content);
    }
    fetchInforUser();
  }, []);
  const handleFile = (fileName, filetype, size) => {
    if (fileName != undefined && filetype != undefined) {
      setFile({ fileName: fileName, filetype: filetype, size: size });
    }
  };

  const handleSubmit = async () => {
    setStateHandleUpload(true);
    try {
      const responeAddPost = await addPost(user.IDAccount, textPost);
      const PostId = responeAddPost.data;
      if (file != null) {
        await UploadFile(file, PostId, handleSizeFile(file.size));
        setStateHandleUpload(false);
      }
      // window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdate = async () => {
    const PostId = dataUpdate.IDPost;
    try {
      const responeUpdatePost = await updatePost(PostId, textPost);
      if (file != null && responeUpdatePost.status == 200) {
        await updateFilePost(file, dataUpdate.ID, PostId);
        setStateHandleUpload(file);
      } else {
        setStateHandleUpload(false);
      }
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Popup width={isMobile == false ? "30%" : "100%"}>
      <div className={cx("wrapper")}>
        <div className={cx("title")}>
          <h2>Đăng bài</h2>
          <div
            className={cx("close")}
            onClick={() => {
              handleClose(false);
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>
        <div className={cx("container_content")}>
          <div className={cx("infor_user")}>
            <div className={cx("image_user")}>
              {inforUser != null ? (
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={
                    inforUser.image_user == null
                      ? images.default_image
                      : inforUser.image_user
                  }
                ></img>
              ) : (
                <></>
              )}
            </div>
            <div className={cx("name_user")}>
              {inforUser != null ? <p>{inforUser.Name}</p> : <></>}

              <div className={cx("state_post")}>
                <FontAwesomeIcon icon={faEarthAmerica} />
                <span>Công khai</span>
              </div>
            </div>
          </div>
          <div className={cx("container_input")}>
            <TextAreaInput
              handleDataText={(e) => {
                setTextPost(e);
              }}
              textUpdate={dataUpdate}
            />
          </div>
          {stateUpload == true || dataUpdate != null ? (
            <div className={cx("container_dragfile")}>
              <DragFile
                handleClose={(state) => {
                  handleStateUpload(state);
                }}
                handleSendFile={handleFile}
                stateUpload={stateHandleUpload}
                fileUpdate={dataUpdate}
                handleStateUpload={(st) => {
                  setStateHandleUpload(st);
                }}
              />
            </div>
          ) : (
            <></>
          )}

          <div className={cx("action_for_post")}>
            <span>Thêm vào bài viết của bạn</span>
            <div
              className={cx("actions", stateUpload == true ? "active" : "")}
              onClick={() => {
                handleStateUpload(null);
              }}
            >
              <FontAwesomeIcon icon={faPaperclip} />
            </div>
          </div>
          <div className={cx("public")}>
            {dataUpdate == null ? (
              <button
                id="submit-post"
                onClick={handleSubmit}
                className={cx(
                  textPost.length > 0 || file != null ? "" : "disable"
                )}
              >
                <span>Đăng</span>
              </button>
            ) : (
              <button
                onClick={handleUpdate}
                className={cx(
                  textPost.length > 0 || file != null ? "" : "disable"
                )}
              >
                <span>Sửa</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default CreatePost;
