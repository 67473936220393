import LayoutEmpty from "../components/layouts/LayoutEmpty/LayoutEmpty";
import Notifications from "../components/Notifications/Notifications";
import configs from "../configs/configs";
import DetailPost from "../pages/DetailPost/DetailPost";
import FilePage from "../pages/FilePage/FilePage";
import FriendPage from "../pages/FriendPage/FriendPage";
import HomePage from "../pages/HomePage/HomePage";
import Login from "../pages/Login/Login";
import Messenger from "../pages/Messenger/Messenger";
import PersonalPage from "../pages/PersonalPage/PersonalPage";
import PostSave from "../pages/PostSave/PostSave";
import Search from "../pages/Search/Search";
import VideoCall from "../pages/VideoCall/VideoCall";
const publicRoute = [
  {
    path: configs.Home,
    component: HomePage,
  },
  {
    path: configs.Login,
    component: Login,
    layout: LayoutEmpty,
  },
  {
    path: configs.PersonalPage,
    component: PersonalPage,
  },
  {
    path: configs.Friends,
    component: FriendPage,
  },
  {
    path: configs.Messenger,
    component: Messenger,
  },
  {
    path: configs.Search,
    component: Search,
  },
  {
    path: configs.Post,
    component: DetailPost,
  },
  {
    path: configs.Notifications,
    component: Notifications,
  },
  {
    path: configs.VideoCall,
    component: VideoCall,
    layout: null,
  },
  {
    path: configs.Files,
    component: FilePage,
  },
  {
    path: configs.PostSave,
    component: PostSave,
  },
];
export default publicRoute;
