export const getFileType = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "mkv"];
  const documentExtensions = [
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
  ];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else if (documentExtensions.includes(extension)) {
    return "file";
  }
  return "unknown";
};
export const handleSizeFile = (size) => {
  const result = size / (1024 * 1024);
  return result.toFixed(2);
};
