import axios from "axios";
const token = localStorage.getItem("token");

export const addPageViews = async (ID, url) => {
  try {
    const response = await axios.post(
      "https://pycheck.xyz/api/v1/addPageViews",
      { ID, url },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};
