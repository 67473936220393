const configs = {
  Home: "/",
  PersonalPage: "/personal",
  Friends: "/friends",
  Login: "/login",
  Messenger: "/messenger",
  Search: "/search",
  Post: "/post",
  Notifications: "/notifications",
  Files: "/files",
  PostSave: "/save-posts",
  VideoCall: "/call",
};
export default configs;
