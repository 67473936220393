import classnames from "classnames/bind";
import styles from "./HomePage.module.scss";
import SideBar from "../../components/layouts/SideBar/SideBar";
import PostItem from "../../components/PostItem/PostItem";
import CreatePost from "../../components/CreatePost/CreatePost";
import { useContext, useEffect, useState } from "react";
import DetailPost from "../../components/DetailPost/DetailPost";
import axios from "axios";
import {
  getPosts,
  getReportType,
  reportPost,
} from "../../services/PostServices";
import Popup from "../../components/Popup/Popup";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import { getInforUser } from "../../services/UserServices";
import images from "../../assets/images";
import { Context } from "../../contexts/Context";
import { addPageViews } from "../../services/SystemServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const cx = classnames.bind(styles);
function HomePage() {
  const [statePopup, setStatePopup] = useState(false);
  const [stateDetailPost, setStateDetailPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [stateReport, setStateReport] = useState(null);
  const [reportTypes, setReportTypes] = useState([]);
  const [dataUpdate, setDataUpdate] = useState(null);
  const [inforUser, setInforUser] = useState(null);

  const { setLoadding } = useContext(Context);

  const user = getUserInfoFromToken();

  const handleStatePopup = (state) => {
    if (state == null) {
      setStatePopup(true);
    } else {
      setStatePopup(false);
    }
  };
  const handleStateDetailPost = (state) => {
    if (state != null) {
      setStateDetailPost(state);
    } else {
      setStateDetailPost(null);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      await setLoadding(true);
      const responsePosts = await getPosts();

      const sortedPosts = await [...responsePosts.data].sort(
        (a, b) => new Date(b.Create_at) - new Date(a.Create_at)
      );
      const fetchReportType = async () => {
        const rs = await getReportType();
        setReportTypes(rs.data);
      };
      fetchReportType();
      setPosts(sortedPosts);
    };
    const fetchInforUser = async () => {
      const responseUser = await getInforUser(user.MSV);
      setInforUser(responseUser.data[0]);
      await setLoadding(false);
    };
    const fetchPageViews = async () => {
      await addPageViews(user.IDAccount, "https://vbsocial.vercel.app");
    };
    if (user != null) {
      fetchPageViews();
      fetchInforUser();
      fetchPosts();
    }
  }, []);
  const handleUpdatePost = (data) => {
    setDataUpdate(data);
    setStatePopup(true);
  };
  const handleReportPost = async (IDType) => {
    const IDSender = user.IDAccount;
    const IDPost = stateReport;
    const rs = await reportPost(IDType, IDPost, IDSender);
    if (rs.status == 200) {
      setStateReport(null);
    } else {
      alert("lỗi rồi");
    }
  };

  return (
    <div className={cx("wrapper")}>
      <div className={cx("container")}>
        <div className={cx("container_sideBar")}>
          <SideBar user={user} />
        </div>
        <div className={cx("home_page")}>
          <div className={cx("container_input_post")}>
            <h3>Đăng bài </h3>
            <div>
              <div className={cx("image_user")}>
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    inforUser != null
                      ? inforUser.image_user == null
                        ? images.default_image
                        : inforUser.image_user
                      : ""
                  }
                ></img>
              </div>
              <div
                className={cx("container_input")}
                onClick={() => {
                  handleStatePopup(null);
                }}
                id="displaypopup"
              >
                <p>Bạn đang cảm thấy như thế nào ?</p>
              </div>
            </div>
          </div>
          <div className={cx("list_post")}>
            {posts.map((post, index) => {
              return (
                <PostItem
                  key={index}
                  dataPostItem={post}
                  handleComment={(s) => {
                    handleStateDetailPost(s);
                  }}
                  fixedComment={null}
                  updatePost={handleUpdatePost}
                  handleReport={() => {
                    setStateReport(post.ID);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      {statePopup == true ? (
        <CreatePost
          handleClose={(s) => {
            handleStatePopup(s);
            setDataUpdate(null);
          }}
          dataUpdate={dataUpdate}
        />
      ) : (
        <></>
      )}
      {stateDetailPost != null ? (
        <DetailPost
          handleClose={(s) => {
            handleStateDetailPost(s);
          }}
          data={stateDetailPost}
        />
      ) : (
        <></>
      )}
      {stateReport != null ? (
        <Popup>
          <div className={cx("container_report")}>
            <div className={cx("title")}>
              <h3>Lý do báo cáo là gì ?</h3>
              <FontAwesomeIcon
                icon={faClose}
                onClick={() => {
                  setStateReport(null);
                }}
                className={cx("icon")}
              />
            </div>
            <ul>
              {reportTypes.map((type) => {
                return (
                  <li
                    onClick={() => {
                      handleReportPost(type.ID);
                    }}
                  >
                    {type.Name}
                  </li>
                );
              })}
            </ul>
          </div>
        </Popup>
      ) : (
        <></>
      )}
    </div>
  );
}

export default HomePage;
