import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import publicRoute from "./routes/routes";
import DefaultLayout from "./components/layouts/DefaultLayout/DefaultLayout";
import { Fragment, useContext } from "react";
import Loadding from "./components/layouts/Loadding/Loadding";
import NotiPopup from "./components/NotiPopup/NotiPopup";
import { Context } from "./contexts/Context";
import LoadImage from "./components/layouts/LoadImage/LoadImage";
import PopupCallNoti from "./components/PopupCallNoti/PopupCallNoti";
import images from "./assets/images";

function App() {
  const { loadding, dataNoti, selectedImage, stateCall, selectedVideo, docs } =
    useContext(Context);
  return (
    <Router>
      <div
        className="App"
        style={{
          height: "100vh",
          // overflowY: "scroll",
          backgroundColor: "#f2f3f4",
        }}
      >
        {loadding == true ? <Loadding /> : <></>}
        {dataNoti != null ? <NotiPopup data={dataNoti} /> : <></>}
        {stateCall != null ? <PopupCallNoti /> : <></>}
        {selectedImage != null ? <LoadImage image={selectedImage} /> : <></>}
        {selectedVideo != null ? (
          <LoadImage image={selectedVideo} state={true} />
        ) : (
          <></>
        )}

        <Routes>
          {publicRoute.map((item, index) => {
            let Layout = DefaultLayout;
            if (item.layout) {
              Layout = item.layout;
            } else {
              if (item.layout === null) {
                Layout = Fragment;
              }
            }
            const Page = item.component;
            return (
              <Route key={index}>
                <Route
                  key={index}
                  path={item.path}
                  element={
                    <Layout>
                      <Page data={item.path}></Page>
                    </Layout>
                  }
                ></Route>
              </Route>
            );
          })}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
