import classnames from "classnames/bind";
import styles from "./Messenger.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClose,
  faFile,
  faPaperclip,
  faPaperPlane,
  faSearch,
  faUser,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import FriendItem from "../../components/FriendItem/FriendItem";
import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { getDataParams, getParams } from "../../utils/urlUtils";
import io from "socket.io-client";
import { getConvens, getMesseages } from "../../services/MessServices";
import { getUserInfoFromToken } from "../../utils/tokenUtils";
import images from "../../assets/images";
import Peer from "simple-peer";
import { descMess, getNameFile } from "../../utils/messUtils";
import { getFileType } from "../../utils/fileUtils";
import { UploadFolder } from "../../services/FileServices";
import { Context } from "../../contexts/Context";

const cx = classnames.bind(styles);
function Messenger() {
  const { selectedImage, setSelectedImage, selectedVideo, setSelectedVideo } =
    useContext(Context);
  const [stateSideBarList, setStateSideBarList] = useState(false);
  const [stateVideo, setStateVideo] = useState(false);
  const [activeMess, setActiveMess] = useState(null);
  const isModile = useMediaQuery({ maxWidth: 670 });
  const refInputFile = useRef();
  const inputRefContent = useRef();
  const videoRef = useRef();
  const location = useLocation();
  const chatContainerRef = useRef(null);

  const [socket, setSocket] = useState(
    io("https://pycheck.xyz", {
      transports: ["websocket"],
      upgrade: true,
    })
  );
  const user = getUserInfoFromToken();
  const [messages, setMessages] = useState([]);
  const [listConven, setListConven] = useState([]);
  const mess = parseInt(getParams(location, "mess"));
  useEffect(() => {
    const fetchConven = async () => {
      const responConven = await getConvens(user.IDAccount);
      if (mess != null) {
        const filter = responConven.data.filter(
          (a) => a.IDConversations == mess
        );
        setActiveMess(...filter);
      }
      setListConven(responConven.data);
    };
    fetchConven();
  }, [mess]);
  useEffect(() => {
    console.log(chatContainerRef.current);
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, activeMess]);
  const submit = (type = "text", content = inputRefContent.current.value) => {
    const IDConversations = activeMess.IDConversations;
    const Sender_id = user.IDAccount;
    const Content = content;
    const content_type = type;
    const Timestamp = new Date();
    const IDPost = IDConversations;
    const IDAccountPost = activeMess.IDOtherAccount;
    const stateNoti = "messenger";
    socket.emit("createMessChat", {
      IDConversations,
      Sender_id,
      Content,
      content_type,
      Timestamp,
    });
    socket.emit("postNotification", {
      IDPost,
      IDAccountPost,
      Sender_id,
      stateNoti,
    });
    inputRefContent.current.value = "";
  };
  const handleSendMess = (e) => {
    const Content = inputRefContent.current.value;
    if (e.key == "Enter") {
      if (Content != "") {
        submit();
      }
    }
  };
  useEffect(() => {
    if (activeMess != 0 && activeMess != null) {
      socket.emit("joinMess", parseInt(activeMess.IDConversations));

      const fetchMess = async () => {
        const responseMessages = await getMesseages(activeMess.IDConversations);
        const desc = descMess(responseMessages.data);
        setMessages(desc);
      };
      fetchMess();
    }
  }, [activeMess]);
  useEffect(() => {
    socket.on("responseMessChat", (message) => {
      setMessages((pre) => [...pre, message]);
    });
    return () => {
      socket.off("responseMessChat");
    };
  }, []);
  const handleSendImage = async (event) => {
    const file = event.target.files[0];
    const file_type = getFileType(file.name);
    const formData = new FormData();
    formData.append("file", file);
    const rs = await UploadFolder(formData);
    const content = `https://pycheck.xyz/uploads/${file.name}`;
    submit(file_type, content);
  };
  const handleVideo = async () => {
    if (stateVideo == true) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };
  return (
    <div className={cx("wrapper")}>
      <div
        className={cx(
          "list_convensation",
          stateSideBarList == true ? "fixed" : ""
        )}
      >
        <div className={cx("action_title")}>
          <h3>Đoạn chat</h3>
          {isModile == true ? (
            <FontAwesomeIcon
              icon={faClose}
              className={cx("icon")}
              onClick={() => {
                setStateSideBarList(!stateSideBarList);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={cx("search_chat")}>
          <div className={cx("container_search")}>
            <FontAwesomeIcon icon={faSearch} />
            <input type="text"></input>
          </div>
        </div>
        <div className={cx("list")}>
          {listConven.map((conven) => {
            return (
              <FriendItem
                onClick={(messeage) => {
                  setActiveMess(messeage);
                  setStateSideBarList(!stateSideBarList);
                }}
                active={
                  activeMess != null
                    ? activeMess.IDConversations == conven.IDConversations
                      ? true
                      : false
                    : ""
                }
                messeage={conven}
              ></FriendItem>
            );
          })}
        </div>
      </div>

      <div className={cx("container_chat")}>
        <div className={cx("title_chat")}>
          {isModile == true ? (
            <div
              className={cx("icon_bar")}
              onClick={() => {
                setStateSideBarList(!stateSideBarList);
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
          ) : (
            <></>
          )}
          {activeMess != null ? (
            <>
              <img
                style={{ objectFit: "cover" }}
                src={
                  activeMess.image_user == null
                    ? images.default_image
                    : activeMess.image_user
                }
              ></img>
              <span className={cx("name")}>{activeMess.Name}</span>
              <a
                href={`/call?room_id=${
                  activeMess != null ? activeMess.IDOtherAccount : null
                }`}
              >
                <FontAwesomeIcon className={cx("icon_video")} icon={faVideo} />
              </a>
            </>
          ) : (
            <></>
          )}
        </div>
        {activeMess == null ? (
          <div className={cx("chat_none")}>
            <span>
              Chưa có đoạn chat hãy lựa chọn đoạn chat hoặc nhắn tin với bạn bè
            </span>
          </div>
        ) : (
          <></>
        )}
        {activeMess != null ? (
          <div className={cx("content_chat")} ref={chatContainerRef}>
            {messages.map((message) => {
              if (message.content_type == "image") {
                return (
                  <div
                    className={cx(
                      "image_mess",
                      message.Sender_id == user.IDAccount
                        ? "sender"
                        : "receiver"
                    )}
                    onClick={() => {
                      setSelectedImage(message.Content);
                    }}
                  >
                    <img src={message.Content}></img>
                  </div>
                );
              } else {
                if (message.content_type == "video") {
                  return (
                    <div
                      className={cx(
                        "image_mess",
                        message.Sender_id == user.IDAccount
                          ? "sender"
                          : "receiver"
                      )}
                    >
                      <video
                        onClick={() => {
                          // setStateVideo(!stateVideo);
                          // handleVideo();
                          setSelectedVideo(message.Content);
                        }}
                        ref={videoRef}
                        src={message.Content}
                      ></video>
                    </div>
                  );
                } else {
                  if (message.content_type == "file") {
                    return (
                      <div
                        className={cx(
                          "container_file",
                          message.Sender_id == user.IDAccount
                            ? "sender"
                            : "receiver"
                        )}
                      >
                        <a
                          className={cx("mess_item")}
                          href={message.Content}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faFile} />
                          <span>{getNameFile(message.Content)}</span>
                        </a>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={cx(
                          "container_mess_item",
                          message.Sender_id == user.IDAccount
                            ? "sender"
                            : "receiver"
                        )}
                      >
                        <div className={cx("mess_item")}>
                          <span> {message.Content}</span>
                        </div>
                      </div>
                    );
                  }
                }
              }
            })}
          </div>
        ) : (
          <></>
        )}
        {activeMess != null ? (
          <div className={cx("input_chat")}>
            <div className={cx("container_input")}>
              <div
                className={cx("icon_upload", "icon")}
                onClick={() => {
                  refInputFile.current.click();
                }}
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </div>

              <input
                onKeyDown={handleSendMess}
                ref={inputRefContent}
                placeholder="Aa"
                type="text"
              ></input>
              <input
                hidden
                ref={refInputFile}
                onChange={handleSendImage}
                type="file"
              ></input>
              <div className={cx("icon_upload", "icon")}>
                <FontAwesomeIcon icon={faPaperPlane} onClick={submit} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={cx("infor_receiver")}>
        {activeMess != null ? (
          <div className={cx("infor")}>
            <img
              style={{ objectFit: "cover" }}
              src={
                activeMess.image_user != null
                  ? activeMess.image_user
                  : images.default_image
              }
            ></img>
            <span className={cx("name")}>{activeMess.Name}</span>
            <div className={cx("actions")}>
              <div
                className={cx("item_action")}
                onClick={() => {
                  window.location.href = `/personal?sinhvien=${activeMess.MSV}`;
                }}
              >
                <div className={cx("icon")}>
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <span> Trang cá nhân</span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Messenger;
